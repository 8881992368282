import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  styled,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { DialogProps } from "../../types/Utils";
import FancyDialogActions from "./FancyDialogActions";

interface AddOrUpdateInventoryTypes {
  familia: string;
  subFamilia: string;
  subSubFamilia: string;
  numeroEquipo: string;
  categoria: string;
  codigoOficial: number;
  tipo: string;
  marca: string;
  color: string;
  codigoSerie: string;
  fechaAdquisicion: string;
  costo: number;
  cantTotal: number;
  cantDisponible: number;
  ubicacionBodega: string;
  estado: boolean;
  disponibilidad: string;
  notas?: string;
}

const AddOrUpdateInventory = ({
  actionButton,
  data,
  onSubmit,
  cancelModal,
  modalType,
}: DialogProps) => {
  const validation = Yup.object().shape({
    familia: Yup.string().required("Campo es requerido"),
    subFamilia: Yup.string().required("Campo es requerido"),
    subSubFamilia: Yup.string().required("Campo es requerido"),
    numeroEquipo: Yup.string().required("Campo es requerido"),
    categoria: Yup.string().required("Campo es requerido"),
    codigoOficial: Yup.number().required("Campo es requerido"),
    tipo: Yup.string().required("Campo es requerido"),
    marca: Yup.string().required("Campo es requerido"),
    color: Yup.string().required("Campo es requerido"),
    codigoSerie: Yup.string().required("Campo es requerido"),
    fechaAdquisicion: Yup.string().required("Campo es requerido"),
    costo: Yup.number().required("Campo es requerido"),
    cantTotal: Yup.number().required("Campo es requerido"),
    cantDisponible: Yup.number().required("Campo es requerido"),
    ubicacionBodega: Yup.string().required("Campo es requerido"),
    estado: Yup.boolean().required("Campo es requerido"),
    disponibilidad: Yup.string().required("Campo es requerido"),
    notas: Yup.string(),
  });

  const defaultValues = {
    id: data?.id ?? 0,
    familia: data?.familia ?? "",
    subFamilia: data?.subFamilia ?? "",
    subSubFamilia: data?.subSubFamilia ?? "",
    numeroEquipo: data?.numeroEquipo ?? "",
    categoria: data?.categoria ?? "",
    codigoOficial: data?.codigoOficial ?? 0,
    tipo: data?.tipo ?? "",
    marca: data?.marca ?? "",
    color: data?.color ?? "",
    codigoSerie: data?.codigoSerie ?? "",
    fechaAdquisicion: data?.fechaAdquisicion ?? "",
    costo: data?.costo ?? 0,
    cantTotal: data?.cantTotal ?? 0,
    cantDisponible: data?.cantDisponible ?? 0,
    ubicacionBodega: data?.ubicacionBodega ?? "",
    estado: data?.estado ?? true,
    disponibilidad: data?.disponibilidad ?? "",
    notas: data?.notas ?? "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AddOrUpdateInventoryTypes>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
  });

  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar producto" : "Actualizar producto"}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form>
          <Grid container spacing={3} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
            <Grid item xs={12} md={4}>
              <Controller
                name={"familia"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Familia"
                    type="text"
                    value={value}
                    variant="standard"
                    {...register("familia")}
                    error={errors.familia && Boolean(errors.familia)}
                    helperText={errors.familia && errors.familia.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name={"subFamilia"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Sub Familia"
                    type="text"
                    value={value}
                    variant="standard"
                    {...register("subFamilia")}
                    error={errors.subFamilia && Boolean(errors.subFamilia)}
                    helperText={errors.subFamilia && errors.subFamilia.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                name={"subSubFamilia"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Sub Sub Familia"
                    type="text"
                    value={value}
                    variant="standard"
                    {...register("subSubFamilia")}
                    error={errors.subSubFamilia && Boolean(errors.subSubFamilia)}
                    helperText={errors.subSubFamilia && errors.subSubFamilia.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={"numeroEquipo"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Número de Equipo"
                    type="text"
                    value={value}
                    variant="standard"
                    {...register("numeroEquipo")}
                    error={errors.numeroEquipo && Boolean(errors.numeroEquipo)}
                    helperText={errors.numeroEquipo && errors.numeroEquipo.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={"categoria"}
                control={control}
                render={({ field }) => (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Categoría</FormLabel>
                    <RadioGroup
                      row
                      {...field}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                    >
                      <FormControlLabel
                        value="insumos"
                        control={<Radio />}
                        label="Insumos"
                      />
                      <FormControlLabel
                        value="instrumentos"
                        control={<Radio />}
                        label="Instrumentos"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={"marca"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Marca"
                    type="text"
                    value={value}
                    variant="standard"
                    {...register("marca")}
                    error={errors.marca && Boolean(errors.marca)}
                    helperText={errors.marca && errors.marca.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={"color"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Color"
                    type="text"
                    value={value}
                    variant="standard"
                    {...register("color")}
                    error={errors.color && Boolean(errors.color)}
                    helperText={errors.color && errors.color.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"codigoOficial"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Código Oficial"
                    type="number"
                    value={value}
                    variant="standard"
                    {...register("codigoOficial")}
                    error={errors.codigoOficial && Boolean(errors.codigoOficial)}
                    helperText={errors.codigoOficial && errors.codigoOficial.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                      min: 0,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"tipo"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Tipo"
                    type="text"
                    value={value}
                    variant="standard"
                    {...register("tipo")}
                    error={errors.tipo && Boolean(errors.tipo)}
                    helperText={errors.tipo && errors.tipo.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"codigoSerie"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Código de Serie"
                    type="text"
                    value={value}
                    variant="standard"
                    {...register("codigoSerie")}
                    error={errors.codigoSerie && Boolean(errors.codigoSerie)}
                    helperText={errors.codigoSerie && errors.codigoSerie.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={"fechaAdquisicion"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Fecha de Adquisición"
                    type="date"
                    value={value}
                    variant="standard"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    {...register("fechaAdquisicion")}
                    error={errors.fechaAdquisicion && Boolean(errors.fechaAdquisicion)}
                    helperText={errors.fechaAdquisicion && errors.fechaAdquisicion.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={"costo"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Costo"
                    type="number"
                    value={value}
                    variant="standard"
                    {...register("costo")}
                    error={errors.costo && Boolean(errors.costo)}
                    helperText={errors.costo && errors.costo.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                      min: 0,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={"cantTotal"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Cantidad Total"
                    type="number"
                    value={value}
                    variant="standard"
                    {...register("cantTotal")}
                    error={errors.cantTotal && Boolean(errors.cantTotal)}
                    helperText={errors.cantTotal && errors.cantTotal.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                      min: 0,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={"cantDisponible"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Cantidad Disponible"
                    type="number"
                    value={value}
                    variant="standard"
                    {...register("cantDisponible")}
                    error={errors.cantDisponible && Boolean(errors.cantDisponible)}
                    helperText={errors.cantDisponible && errors.cantDisponible.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                      min: 0,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"ubicacionBodega"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Ubicación en Bodega"
                    type="text"
                    value={value}
                    variant="standard"
                    {...register("ubicacionBodega")}
                    error={errors.ubicacionBodega && Boolean(errors.ubicacionBodega)}
                    helperText={errors.ubicacionBodega && errors.ubicacionBodega.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={"disponibilidad"}
                control={control}
                render={({ field }) => (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Disponibilidad</FormLabel>
                    <RadioGroup
                      row
                      {...field}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                    >
                      <FormControlLabel
                        value="disponible"
                        control={<Radio />}
                        label="Disponible"
                      />
                      <FormControlLabel
                        value="enProyecto"
                        control={<Radio />}
                        label="En Proyecto"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name={"estado"}
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label="Estado"
                    variant="standard"
                    {...field}
                    value={field.value ? "true" : "false"}
                    onChange={(e) => {
                      const selectedType = e.target.value === "true";
                      field.onChange(selectedType);
                    }}
                    error={errors.estado && Boolean(errors.estado)}
                    helperText={errors.estado && errors.estado.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 224,
                            width: 250,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="true">Disponible</MenuItem>
                    <MenuItem value="false">Entregado</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"notas"}
                control={control}
                render={({ field: { value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Notas"
                    type="text"
                    value={value}
                    variant="standard"
                    {...register("notas")}
                    error={errors.notas && Boolean(errors.notas)}
                    helperText={errors.notas && errors.notas.message}
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off",
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <FancyDialogActions
        handleAccept={handleSubmit(onSubmit)}
        cancelModal={cancelModal}
      />
    </>
  );
};

export default AddOrUpdateInventory;
