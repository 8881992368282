import { FC, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  useTheme,
  Paper,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import LogoMR from "../../resources/images/imagen_logo_mrpintores.png";
import loginScreen from "../../resources/images/login_screen.jpg";
import { loginTypes } from "../../types/Login";
import { SVloginCodeRequest, SVloginRequest } from "../../services/Security";
import { useApp } from "../../hooks/useApp";
import Footer from "../../components/Footer";

// Estilos personalizados
const LoginContainer = styled(Grid)(({ theme }) => ({
  height: "100vh",
  backgroundColor: "#f5f5f5",
}));

const LeftSide = styled(Grid)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "2rem",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const RightSide = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${loginScreen})`,
  backgroundSize: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "2rem",
  height: "100vh",
}));

const Logo = styled("img")({
  width: "500px", // Ajusta el tamaño del logo
  height: "auto",
});

const CustomButton = styled(Button)({
  backgroundColor: "#d32f2f",
  color: "#fff",
  width: "200px",
  "&:hover": {
    backgroundColor: "#b71c1c",
  },
});

const Login: FC = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const {
    authInfo,
    isLoading,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    successMsg,
    setSuccessMsg,
    errorMsg,
    loginProvider,
    resetErrorMsg,
    resetSuccessMsg,
  } = useApp();

  const validation = Yup.object().shape({
    username: Yup.string().required("El nombre de usuario es requerido"),
    password: Yup.string().required("La contraseña es requerida"),
  });

  const defaultValues = {
    username: "",
    password: "",
  };

  const { register, handleSubmit, formState: { errors }, control } = useForm({
    defaultValues,
    resolver: yupResolver(validation),
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleLogin = async (data: loginTypes) => {
    console.log(data)
    try {
      setLoading(true);
      //Authenticate user
      const loginData = await SVloginRequest(data);
      await loginProvider(loginData);
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setErrorMsg(error.message);
    }
  };

  const onSubmit = async (data: any) => {
    await handleLogin(data);
  };
  return (
    <LoginContainer container>
      {/* Columna izquierda */}
      <LeftSide item xs={12} md={6}>
        <Typography textAlign="center" variant="h4" color="primary" fontWeight="bold" gutterBottom sx={{ mb: 10 }}>
          ¡BIENVENIDO DE NUEVO!
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="center" alignItems="center" spacing={3} sx={{ mb: 10 }}>
            <Paper
              sx={{
                background: "#FFFFFF",
                py: 10,
                px: 10,
                //boxShadow: "none",
              }}
            >
              <Grid item xs={12} textAlign="center">
                <Typography fontWeight="bold" sx={{ ml: -50 }}>
                  Nombre de usuario
                </Typography>
              </Grid>
              <Grid item xs={12} textAlign="center" sx={{ mb: 3 }}>
                <Controller
                  name="username"
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      //label="Nombre de usuario"
                      type="email"
                      sx={{
                        width: '350px', marginBottom: '20px', '& .MuiOutlinedInput-root': {
                          borderRadius: '30px', // Bordes redondeados
                        },
                      }}
                      {...register("username")}
                      error={!!errors.username}
                      helperText={errors.username?.message}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlineIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Typography fontWeight="bold" sx={{ ml: -65 }}>
                  Contraseña
                </Typography>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Controller
                  name="password"
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      //label="Contraseña"
                      sx={{
                        width: '350px', marginBottom: '20px', '& .MuiOutlinedInput-root': {
                          borderRadius: '30px', // Bordes redondeados
                        },
                      }}
                      type={showPassword ? "text" : "password"}
                      {...register("password")}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlinedIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClickShowPassword}>
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label="Recuérdame"
              />
            </Grid> */}
              <Grid item xs={12} textAlign="center" sx={{ ml: 40 }}>
                <Link to="/rememberPassword" style={{ textDecoration: 'none', color: "#8699DA" }}>
                  ¿Olvidaste tu contraseña?
                </Link>
              </Grid>
              <Grid item xs={12} textAlign="center" sx={{ mb: 10 }}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleSubmit(onSubmit)}  // Maneja el evento de envío de formulario
                  sx={{
                    mt: 3,  // Espaciado superior (margen superior)
                    width: '350px',  // Ajusta el ancho del botón
                    backgroundColor: '#D32F2F',  // Color de fondo
                    fontSize: '16px',
                    borderRadius: '30px',
                    color: '#FFF',  // Color del texto
                    '&:hover': {
                      backgroundColor: '#C62828',  // Color de fondo al hacer hover
                    },
                  }}
                >
                  Iniciar sesión
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </form>
        <Footer />
      </LeftSide>

      {/* Columna derecha */}
      <RightSide item xs={12} md={6}>
        <Logo src={LogoMR} alt="Company Logo" />
      </RightSide>
    </LoginContainer>
  );
};

export default Login;
