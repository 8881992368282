import { CONSTANT } from "./Constants";
export const URL = {
  /** HOME PATH */
  LOGIN_TWOFACTORCODE_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/login/twofactor",
  LOGIN_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/autenticate",
  LOGIN_RECOVERY_PASS_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/recoverPasswordAdmin",
  LOGIN_CHANGE_PASS_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/changePassword",
  LOGIN_ACTIVE_USER_REQUEST: CONSTANT.DISPATCHER_USER_PREFIX + "/user/activate",
  SESSIONVALIDATE_REQUEST:
    CONSTANT.DISPATCHER_USER_PREFIX + "/user/validatesession",

  /** users urls */
  REQUEST_USER_LIST: (page: string, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/user/{page}/{size}?page=${page}&size=${size}`,
  REQUEST_USER_LIST2: (page: number, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/user/{page}/{size}?page=${page}&size=${size}`,

  REQUEST_USER_LIST_FILTER: (page: string, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/user/${page}/${size}`,
  REQUEST_USER_CREATE: CONSTANT.DISPATCHER_USER_PREFIX + `/user/createAdmin`,
  REQUEST_USER: (id: any) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/user/${id ? id : ""}`,
  REQUEST_USER_PASS: CONSTANT.DISPATCHER_USER_PREFIX + `/user/changePassword`,
  REQUEST_ROLE_LIST: CONSTANT.DISPATCHER_USER_PREFIX + `/role/`,
  REQUEST_ACTIVATE_USER: (code: string, username: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/user/activate?code=${code}&username=${username}`,
  REQUEST_VALIDATE_AVAILABLE_USER: (username: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/user/validateUserName?username=${username}`,

  REQUEST_VALIDATE_AVAILABLE_PASSWORD: (userId: number) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/user/validateNewPassword?userId=${userId}`,



   /** Role urls */
   REQUEST_ROLE: (id: string | null, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/role/${id ? id : ""}`,
  REQUEST_LIST_ROLE: (filter: string, page: number, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/role/${page}/${size}${filter ? "?filter=" + filter : ""}`,
  REQUEST_ROLE2: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/role/${id ? id : ""}`,
  REQUEST_ROLE_BY_ID: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/role/${id ? id : ""}`,
  REQUEST_ROLE_CREATE: CONSTANT.DISPATCHER_USER_PREFIX + `/role/`,
  REQUEST_ROLE_UPDATE: (id: string | null)=> CONSTANT.DISPATCHER_USER_PREFIX + `/role/${id}`,
  REQUEST_ROLE_DELETE: CONSTANT.DISPATCHER_USER_PREFIX + `/role/`,



  /** Company urls */
  REQUEST_COMPANY: (page: any, size: any, filter: any) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/company/all/${page}/${size}${filter ? `?filter=${filter}` : ""}`,

  REQUEST_COMPANY_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/save`,
  REQUEST_COMPANY_DELETE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/company/delete`,


  /** Inventory urls */
  REQUEST_INVENTORY: (page: any, size: any, filter: any) =>
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX +
    `/inventory/all/${page}/${size}${filter ? `?filter=${filter}` : ""}`,

  REQUEST_INVENTORY_CREATE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/inventory/save`,
  REQUEST_INVENTORY_DELETE:
    CONSTANT.DISPATCHER_MAINTENANCE_PREFIX + `/inventory/delete`,

  /** authority */
  REQUEST_AUTHORITY_LIST: (filter: string, page: number, size: string) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/authority/?${filter ? "filter=" + filter + "&" + "page=" + page + "&" + "size=" + size : "page=" + page + "&" + "size=" + size}`,

  REQUEST_AUTHORITY: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority/${id ? id : ""}`,
  REQUEST_AUTHORITY_CREATE: CONSTANT.DISPATCHER_USER_PREFIX + `/authority`,

  /** authorityByRole */
  REQUEST_AUTHORITY_BY_ROLE_LIST: (
    roleId: string,
    page: number,
    size: number,
    filter: string
  ) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/authority-role/${roleId}/${page}/${size}/?${filter ? "filter=" + filter + "&" : ""}`,
  REQUEST_AUTHORITY_BY_ROLE: (id: string | null) =>
    CONSTANT.DISPATCHER_USER_PREFIX + `/authority-role/${id ? id : ""}`,

  REQUEST_AUTHORITY_NO_ADDED_BY_ROLE_LIST: (
    roleId: number,
    page: number,
    size: number,
    filter: string
  ) =>
    CONSTANT.DISPATCHER_USER_PREFIX +
    `/authority/${roleId}/${page}/${size}?${filter ? "filter=" + filter + "&" : ""
    }${filter ? "&platform=mobile" : "platform=mobile"}`




























};


