import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Grid, Box, Typography, Divider, useTheme, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import BusinessIcon from "@mui/icons-material/Business";
import EventIcon from "@mui/icons-material/Event";
import ComparativeGraph from "../components/ComparativeGraph";
import CardDashboard from "../components/CardDashboard";
// import PieChartDashboard from "../components/PieChartDashboard";
import MopedIcon from '@mui/icons-material/Moped';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { useApp } from "../hooks/useApp";
import FancyPaper from "../components/FancyPaper";
import { useForm } from "react-hook-form";
import { StringLiteral } from "typescript";
import dayjs from "dayjs";

const DashboardHome = () => {
  const { setLoading, setErrorMsg } = useApp();
  const [totalSales, setTotalSales] = useState<any>(null);
  const [percentageCoverage, setPercentageCoverage] = useState<any>(null);
  const [companyByCategory, setCompanyByCategory] = useState<any>(null);
  const [salesByMonth, setSalesByMonth] = useState<any>([]);
  const [aveRange, setAveRange] = useState<any>(null);
  const [aveHours, setAveHours] = useState<any>(null);
  const currentYear = new Date().getFullYear();
  const [selectedMonth, setSelectedMonth] = useState<string>("global");
  const { handleSubmit } = useForm();

  return (
    <FancyPaper pagetitle={"Inicio"}>
      
    </FancyPaper>
  );
};

export default DashboardHome;
