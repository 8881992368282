import {
  Box,
  Paper,
  Typography,
  Divider,
  useTheme,
  Toolbar,
  IconButton,
  Avatar,
  styled,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router";
import { useApp } from "../hooks/useApp";

const PageTitle = styled(Typography)(({ theme }) => ({
  //position: "absolute",
  //left: "310px",
  //top: "10px",
  fontSize: "1.5rem",
  zIndex: "10000",
  fontWeight: "700",
  lineHeight: "1",
}));

const FancyPaper = (props: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { authInfo } = useApp();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "15px 35px",
        }}
      >
        <PageTitle variant="caption" color="text.primary">
          {props.pagetitle}
        </PageTitle>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
          sx={{
            background: "#fff",
            borderRadius: "25rem",
            padding: "7px 15px 7px 7px",
            "&:hover, &:focus": {
              background: "transparent",
            },
            "& .MuiTouchRipple-root": {
              display: "none",
            },
          }}
        >
          <Avatar sx={{ mr: 2 }}>
            <PersonIcon sx={{ fontSize: "24px" }} color="primary" />
          </Avatar>

          <Typography variant="body1" sx={{ fontSize: "1rem" }}>
            {authInfo?.name}
          </Typography>
        </IconButton>
      </Box>
      <Divider sx={{ borderColor: "#00000026" }} />
      <Box
        sx={{
          p: {
            xs: 5,
            md: `${theme.spacing(12)} ${theme.spacing(12)} ${theme.spacing(6)}`,
          },
          overflow: "hidden",
        }}
      >
        {/* <Typography
          variant="caption"
          color="info.dark"
          onClick={() => navigate("/")}
          sx={{
            cursor: "pointer",
            "&:hover": {
              color: theme.palette.primary.main,
            },
          }}
        >
          Inicio{" "}
        </Typography>
        {props.prevpage && props.linkprevpage && (
          <Typography
            variant="caption"
            color="info.dark"
            onClick={() => navigate(props.linkprevpage)}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: theme.palette.primary.main,
              },
            }}
          >
            {"/ "} {props.prevpage}
          </Typography>
        )}
        <Typography variant="caption" color="text.primary">
          {"/ "} {props.pagetitle}
        </Typography>

        <Divider /> */}
        <Paper
          sx={{
            padding: {
              xs: 5,
              md: 8,
            },
            mt: {
              xs: 5,
              md: 10,
            },
            mb: 5,
          }}
          {...props}
        >
          {props.children}
        </Paper>
      </Box>
    </>
  );
};

export default FancyPaper;
