import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { CONSTANT } from "../../utils/Constants";
import {UrlBase} from "../../url/Urls"
import { AuthorityResponse } from "../authority/AuthorityInterfaces"; // Adjust the import path as necessary

export const getRequest = async (roleId: string, page: number,  filter:string, size: number) => {
  let responseData = {};
  await privateFetch
    .get(
      URL.REQUEST_AUTHORITY_BY_ROLE_LIST(roleId,page,size ?? UrlBase.registration_x_page,filter))
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en consulta de permisos por perfil");
    });
  console.log("url", URL.REQUEST_AUTHORITY_BY_ROLE_LIST(roleId,page,size ?? UrlBase.registration_x_page,filter));
  return responseData;
};

export const createRequest = async (data: any) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["authorityId", data.authorityId],
    ["roleId", data.roleId]
  ]);

  await privateFetch
    .post(
      URL.REQUEST_AUTHORITY_BY_ROLE(null),
      {
        headers: {
          ...CONSTANT.HEADER_TYPE,
        },
      },
      { params }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en proceso de guardar permiso por perfil");
    });
  return responseData;
};

export const deleteRequest = async (data: any) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["authorityId", data.authorityId],
    ["roleId", data.roleId]
  ]);
  await privateFetch
    .delete(URL.REQUEST_AUTHORITY_BY_ROLE(null),
    { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error en proceso de eliminar permiso por perfil");
    });
  return responseData;
};

 export const getRequestNoAdded = async (roleId: number, page: number,filter:string, size?:number): Promise<AuthorityResponse> => {
    let responseData = {} as AuthorityResponse;
  
    await privateFetch
      .get(
        URL.REQUEST_AUTHORITY_NO_ADDED_BY_ROLE_LIST(
          roleId,
          page,
          //@ts-ignore
          size || size === 0 ? size : UrlBase.registration_x_page,  
          filter
        )
      )
      .then(async (response) => {
        responseData = await response.data;
      })
      .catch((error) => {
        throw new Error("Error en consulta de permisos");
      });
    return responseData;
  };
