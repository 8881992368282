import { URL } from "../../utils/UrlConstants";
import { privateFetch } from "../../utils/CustomFetch.js";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/UrlContants";
import { UrlBase } from "../../utils/Urls";
import { Console } from "console";
import { serialize } from 'object-to-formdata';

// Opciones para la serialización
const options = {
  indices: true,
  dotsForObjectNotation: true,
};

export const getRequestUser = async (page: any, filter: any) => {
  let responseData = {};
  const params = new URLSearchParams([["filter", filter]]);
  await privateFetch
    .get(
      URL.REQUEST_USER_LIST(page, UrlBase.registration_x_page),
      { params }
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);

    });
  return responseData;
};

//REQUEST_USER_LIST_USERS_NO_BIKER
export const getRequestUser2 = async (page: number, filter: string) => {
  let responseData = {};
  const params = new URLSearchParams([["filter", filter]]);
  await privateFetch
    .get(URL.REQUEST_USER_LIST2(page, UrlBase.registration_x_page), { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
  return responseData;
};

export const findByIdRequest = async (id: number) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_USER(id), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_role_findid_error);
    });
  return responseData;
};

export const createUser = async (data: any) => {
  let responseData = {};
  const requestBody = {
    username: data.username,
    firstName: data.firstName,
    lastName: data.lastName,
    phone: data.phone,
    roleID: data.roleId,
    password: data.password,
    userCreated: data.userCreated,
  };

  await privateFetch
    .post(URL.REQUEST_USER_CREATE, requestBody, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
      console.log(error)
    });
  return responseData;
};

export const updateUser = async (data: any) => {
  let responseData = {};
  const requestBody = {
    firstName: data.firstName,
    id: data.id,
    lastName: data.lastName,
    password: data.password,
    phone: data.phone,
    roleID: data.roleId,
  };
  console.log(requestBody)
  
  await privateFetch
    .put(URL.REQUEST_USER(null), requestBody, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_user_update_error);
      //console.log(error)
    });
  return responseData;
};

export const updatePassRequest = async (data: any) => {
  let responseData = {};
  console.log(data)
  const requestBody = {
    id: data.id,
    password: data.password
  };
  await privateFetch
    .put(URL.REQUEST_USER_PASS, JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(properties.com_parval_label_user_update_error);
    });
  return responseData;
};

export const deleteRequest = async (id: any) => {
  let responseData = {};
  await privateFetch
    .delete(URL.REQUEST_USER(id), {})
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(
        properties.com_parval_label_user_delete_error
      );
    });
  return responseData;
};

export const activateUser = async (code: string, username: string) => {
  let responseData = {};
  await privateFetch
    .get(URL.REQUEST_ACTIVATE_USER(code, username), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error("Error activando usuario");
    });
  return responseData;
};

export const valideAvailableUser = async (username: string) => {
  try {
    const response = await privateFetch.get(URL.REQUEST_VALIDATE_AVAILABLE_USER(username), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    });

    console.log(response);

    const isAvailable = response.data && response.data.exists === true;

    return isAvailable;
  } catch (error) {
    console.log(error);
    throw new Error("Error con el usuario");
  }
};

export const valideAvailablePassword = async (userId: number, password: string) => {
  try {
    const requestBody = {
      newPassword: password.toString(),
    };

    const response = await privateFetch.post(URL.REQUEST_VALIDATE_AVAILABLE_PASSWORD(userId), JSON.stringify(requestBody), {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    });

    console.log(response);

    const isAvailable = response.data && response.data.matches === true;

    console.log(isAvailable);

    return isAvailable;
  } catch (error) {
    console.log(error);
    throw new Error("Error con el usuario");
  }
};