import { Grid, styled } from "@mui/material";

const Loading = () => {
  const AnimationLoadWrapp = styled("div")(({ theme }) => ({
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    height: "100%",
    left: "0",
    position: "fixed",
    top: "0",
    width: "100%",
    zIndex: "10000",
  }));

  const AnimationLoad = styled("div")(({ theme }) => ({
    width: "50px",
    height: "50px",
    left: "50%",
    top: "50%",
    marginTop: "-25px",
    marginLeft: "-25px",
    aspectRatio: "1",
    borderRadius: "50%",
    background: "#FF0000",
    boxShadow: "0 0 0 0 #CC0000",
    animation: "loading 1.5s infinite linear",
    position: "absolute",
    "&:after, &:before": {
      content: '""',
      position: "absolute",
      inset: "0",
      borderRadius: "inherit",
      boxShadow: "0 0 0 0 #FF0000",
      animation: "inherit",
      animationDelay: "-0.5s",
    },
    "&:after": {
      animationDelay: "-1s",
    },
    "@keyframes loading": {
      "100%": { boxShadow: "0 0 0 40px #0000" },
    },
  }));

  return (
    <Grid
      container
      sx={{
        background: "#fff",
      }}
    >
      <AnimationLoadWrapp>
        <AnimationLoad />
      </AnimationLoadWrapp>
    </Grid>
  );
};

export default Loading;
