import {
  Stack,
  Link,
  Typography,
  styled,
  useTheme,
  SvgIcon,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

import Facebook from "../resources/images/Icono Facebook.png";
import Instagram from "../resources/images/Icono Instagram.png";
import LinkedIn from "../resources/images/Icono Linkedln.png";

const LinkText = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.secondary.dark,
  display: "inline-block",
  marginBottom: "0.15rem",
}));

const HoverableIcon = styled(SvgIcon)(({ theme }) => ({
  fontSize: "1.625rem",
  width: 26,
  transition: "color 0.3s ease",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

// Estilos para las imágenes de los íconos
const IconImage = styled("img")(({ theme }) => ({
  width: "2.5rem", // Ajusta el tamaño de la imagen según sea necesario
  height: "2.5rem",
  transition: "transform 0.3s ease", // Transición suave
  "&:hover": {
    transform: "scale(1.1)", // Efecto de zoom al pasar el mouse
  },
}));

const Footer = () => {
  const theme = useTheme();
  return (
    <>
      {/* Redes sociales en fila */}
      <Stack justifyContent="center" alignItems="center" spacing={1.5}>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={6}>
          <Link
            href="https://instagram.com/bitcodeenterprise"
            target="_blank"
            underline="none"
          >
            <IconImage src={Instagram} alt="Instagram" />
          </Link>
          <Link
            href="https://www.facebook.com/bitcodeenterprise/"
            target="_blank"
            underline="none"
          >
            {/* Utiliza las imágenes PNG en lugar de los íconos */}
            <IconImage src={Facebook} alt="Facebook" />
          </Link>
          <Link
            href="https://www.linkedin.com/company/bitcode-enterprise/mycompany/"
            target="_blank"
            underline="none"
          >
            <IconImage src={LinkedIn} alt="LinkedIn" />
          </Link>
        </Stack>

        {/* Correo electrónico */}
        <Typography>
          <LinkText href="mailto:mrpintores@mail.com">mrpintores@gmail.com</LinkText>
        </Typography>

        {/* Texto de copyright */}
        {/* <Typography color="secondary.dark" variant="body1">
          © 2024 by Bitcode Enterprise.
        </Typography> */}
      </Stack>
    </>
  );
};

export default Footer;
