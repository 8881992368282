import { lazy } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  defer,
  Navigate,
} from "react-router-dom";
import { AuthLayout } from "../components/security/AuthLayout";
import { getAuthData } from "../utils/LocalStorageManager";
import DashboardLayout from "../layouts/DashboardLayout";
import DashboardHome from "../pages/DashboardHome";
import Auth from "../pages/session/Auth";
import Login from "../pages/session/Login";
import RememberPassword from "../pages/session/RememberPassword";
import ForgotPassword from "../pages/session/ForgotPassword";
import ActiveRegister from "../pages/session/ActiveRegister";
import ManagementRole from "../pages/ManagementRole";
import ManagementUser from "../pages/ManagementUsers";
import SettingAuthority from "../pages/SettingAuthority";
import SettingAuthorityByRoleManagement from "../pages/SettingAuthorityByRoleManagement";
import ManagementInventory from "../pages/maintenance/ManagementInventory";
import ManagementCompany from "../pages/settings/ManagementCompany";
import CancelSubscription from "../pages/session/CancelSubscription";

export const AppRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >
      <Route path="/" element={<Auth />}>
        <Route index element={<Login />} />
        <Route path="/rememberPassword" element={<RememberPassword />} />
        <Route
          path="/forgot-password/:code/:email"
          element={<ForgotPassword />}
        />
        <Route
          path="/active-register/:code/:email"
          element={<ActiveRegister />}
        />
      </Route>
      <Route path="/deleteaccount" element={<CancelSubscription />} />
      <Route path="/dashboard" element={<DashboardLayout />}>
        <Route path="home" element={<DashboardHome />} />
        <Route path="settingroles" element={<ManagementRole />} />
        <Route path="settinguser" element={<ManagementUser />} />
        <Route path="settingauthority" element={<SettingAuthority />} />
        <Route
          path="authoritiesbyrole/:roleId"
          element={<SettingAuthorityByRoleManagement />}
        />
        <Route path="inventory" element={<ManagementInventory />} />
        <Route path="company" element={<ManagementCompany />} />
      </Route>
    </Route>
  )
);
