import { useState, useEffect } from "react";
import FakeDataInventario from "../fakeData/FakeDataInventario";
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
  TextField,
  Button,
  Stack,
  Dialog,
  Checkbox,
} from "@mui/material";
import {
  KeyIcon,
  TrashIcon,
  PlusIcon,
  PenIcon,
} from "../../components/svgicons/SvgIcons";
import FancyTooltip from "../../components/utils/FancyTooltip";
import FancyPaper from "../../components/FancyPaper";
import { useForm } from "react-hook-form";
import DeleteModal from "../../components/utils/DeleteModal";
import ResourceAccess from "../../components/security/ResourceAccess";
import FancyTablePagination from "../../components/utils/FancyTablePagination";
import { useApp } from "../../hooks/useApp";
import AddOrUpdateInventory from "../../components/utils/AddOrUpdateInventory";
import FancyDialogActions from "../../components/utils/FancyDialogActions";
import { properties } from "../../utils/Properties_es";
import {
  createRequest,
  deleteRequest,
  getRequest,
  updateRequest,
} from "../../services/inventory/InventoryService";

const ManagementInventory = () => {
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const [inventoryData, setInventoryData] = useState<any>([]);
  const [preFilter, setPreFilter] = useState<any>("");
  const [formData, setFormData] = useState<any>("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { handleSubmit } = useForm();

  const initForm = {
    id: 0,
    product: "",
    serial: "",
  };

  useEffect(() => {
    // Asignar la fake data al estado
    setInventoryData(FakeDataInventario);
  }, []);

  const handleFetchData = async (currentPage: number, filter: string) => {
    setLoading && setLoading(true);
    try {
      let data = await getRequest(currentPage, filter);
      if (data) {
        setInventoryData(data);
      }
      setLoading && setLoading(false);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleAdd = async (data: any) => {
    console.log(data);
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let createData = await createRequest({
        ...data,
        user: authInfo?.username,
      });

      if (!createData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_inventory_create_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_inventory_create);
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleUpdate = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    console.log(data);
    try {
      let updateData = await updateRequest({
        ...data,
        user: authInfo?.username,
      });

      if (!updateData) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_inventory_update_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_inventory_update);

      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  const handleDelete = async (data: any) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData: any = await deleteRequest({
        ...data,
      });
      console.log(deleteData);
      if (!deleteData || deleteData.deleted !== true) {
        setErrorMsg &&
          setErrorMsg(properties.com_parval_label_inventory_delete_error);
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg &&
        setSuccessMsg(properties.com_parval_label_inventory_delete);
      //reset page and call fetch data
      setPage(0);
      await handleFetchData(0, "");
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Efecto para validar errores en caso de abrir modal
   */
  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  /**
   * Evento de apertura de modal
   */
  const handleOpenModal = async (event: any) => {
    event.preventDefault();
    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");

    if (modalAction === "update") {
      object = inventoryData.content.find((p: any) => p.id === parseInt(id));
    }

    if (modalAction === "delete") {
      object = inventoryData.content.find((p: any) => p.id === parseInt(id));
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        modalObject: object,
      });
  };

  const handleFetchByID = async (id: number) => {
    setLoading && setLoading(true);
    try {
      /* let userData = await findByIdRequest(id);
      if (userData) {
        //@ts-ignore
        setFormData(userData.content);
      } */
      setLoading && setLoading(false);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
      setLoading && setLoading(false);
    }
  };

  /**
   * Evento de aplicar filtro de busqueda
   */
  const handleApplyFilter = async () => {
    if (preFilter !== "") {
      await handleFetchData(0, preFilter);
    }
  };

  /**
   * Evento de cierre de modal
   * @param event
   */
  const handleCancelModal = () => {
    //@ts-ignore
    if (modalData?.modalType !== "delete") {
      setFormData(initForm);
    }
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage - 1);
    let customPage = newPage - 1;
    if (customPage !== page) {
      await handleFetchData(customPage, preFilter);
    }

    /* if (customPage !== page) {
      await handleFetchData(customPage, filter);
    } */
    //await handleFetchData(0, filter);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmit = async (data: any) => {
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "update":
        await handleUpdate(data);
        break;
      case "delete":
        //@ts-ignore
        await handleDelete(modalData?.modalObject);
        break;
      default:
        break;
    }
    if (modalData?.modalType !== "delete") {
      setFormData(formData);
    }
  };

  console.log(inventoryData);

  return (
    <>
      <FancyPaper pagetitle="Inventario">
      
        <Grid container spacing={8} sx={{ pb: 8 }}>
          <Grid item md={9} sm={6} xs={12}>
            {/* <ResourceAccess isCode={true} pathOrCode={"INVENTORY:WRITE"}> */}
            <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                data-name="create"
              >
                Agregar producto
                <PlusIcon sx={{ ml: 1 }} />
              </Button>
            {/* </ResourceAccess> */}
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <TextField
                placeholder="Filtro de búsqueda"
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    borderRadius: "0.2rem 0 0 0.2rem",
                  },
                  "& fieldset": {
                    borderRightWidth: "0",
                  },
                }}
                value={preFilter}
                onChange={(e: any) => {
                  setPreFilter(e.target.value);
                  if (e.target.value === "") {
                    handleFetchData(0, "");
                  }
                }}
                onKeyDown={(e) => e.key === "Enter" && handleApplyFilter()}
              />
              <Button
                variant="contained"
                color="secondary"
                onClick={handleApplyFilter}
                sx={{
                  borderRadius: "0 0.2rem 0.2rem 0!important",
                }}
              >
                Buscar
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <TableContainer>
  <Table sx={{ minWidth: 650 }} aria-label="inventory table">
    <TableHead>
      <TableRow>
        <TableCell>Código</TableCell>
        <TableCell align="center">Descripción</TableCell> 
        <TableCell align="center">Cantidad Disponible</TableCell>
        <TableCell align="center">Disponibilidad</TableCell>
        <TableCell align="center">Acciones</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {inventoryData?.content?.map((row: any) => (
        <TableRow key={row.id}>
          <TableCell>{`${row.id}`}</TableCell>
          <TableCell align="center">{row.notas}</TableCell>
          <TableCell align="center">{row.cantDisponible}</TableCell>
          <TableCell align="center">{row.disponibilidad}</TableCell>
          <TableCell align="center">
            {/* <ResourceAccess isCode={true} pathOrCode={"INVENTORY:WRITE"}> */}
              <FancyTooltip title="Editar" placement="top">
                <IconButton
                  aria-label="edit"
                  component="label"
                  color="primary"
                  onClick={handleOpenModal}
                  data-name="update"
                  data-id={row.id}
                >
                  <PenIcon />
                </IconButton>
              </FancyTooltip>
              <FancyTooltip title="Eliminar" placement="top">
                <IconButton
                  aria-label="trash"
                  component="label"
                  color="error"
                  data-id={row.id}
                  onClick={handleOpenModal}
                  data-name="delete"
                >
                  
                  <TrashIcon />
                </IconButton>
              </FancyTooltip>
            {/* </ResourceAccess> */}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>
        <FancyTablePagination
          count={
            inventoryData?.content?.length > 0
              ? inventoryData?.content?.length
              : 0
          }
          rowsPerPage={inventoryData.size}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          totalElements={inventoryData.totalElements}
          totalPages={inventoryData.totalPages}
        />
      </FancyPaper>
      {(modalData?.modalType === "create" ||
        modalData?.modalType === "update") && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <AddOrUpdateInventory
            data={modalData?.modalObject}
            onSubmit={onSubmit}
            cancelModal={handleCancelModal}
            modalType={modalData?.modalType}
          />
        </Dialog>
      )}
      {modalData?.modalType === "delete" && (
        <Dialog
          open={modalData.modalOpen}
          onClose={handleCancelModal}
          fullWidth
        >
          <DeleteModal
            //@ts-ignore
            textChildren={modalData?.modalObject?.username}
            actionButton={
              <>
                <FancyDialogActions
                  handleAccept={handleSubmit(onSubmit)}
                  cancelModal={handleCancelModal}
                  textAcceptButton={"Eliminar"}
                />
              </>
            }
          />
        </Dialog>
      )}
    </>
  );
};

export default ManagementInventory;