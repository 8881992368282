import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useApp } from "../../hooks/useApp";
import { getRequest, getRequestRoleList } from '../../services/role/RoleService';
import { activateUser, valideAvailableUser } from "../../services/user/User";
import { formSchemaOptionsName } from "../../types/Utils";
import { properties } from "../../utils/Properties_es";
import { requiredString } from "./Validations";
import { MarkEmailRead } from "@mui/icons-material";
import { PatternFormat } from "react-number-format";
import { createUser, updateUser } from "../../services/user/User";
import { Content } from '../../pages/styles';

interface ValidationResult {
  exists: boolean;
  // otras propiedades si las hay
}

interface AddOrUpdateUserTypes {
  id?: number;
  username: string;
  firstName: string;
  lastName: string;
  password?: string;
  phone: string;
  status?: string;
  roleId: number;
}

interface DialogProps {
  actionButton?: any;
  data: any;
  onSubmit: any;
  cancelModal: any;
  modalType: string;
  roleData?: any;
  handleActiveUser?: any;
}
const AddOrUpdateUserModal = ({
  actionButton,
  data,
  roleData,
  onSubmit,
  cancelModal,
  modalType,
  handleActiveUser,
}: DialogProps) => {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);

  const validation = Yup.object().shape({
    username: Yup.string()
      .required("Campo es requerido")
      .email("Email es inválido"),
    firstName: Yup.string().required("Campo es requerido"),
    lastName: Yup.string().required("Campo es requerido"),
    password: Yup.string().when(modalType, () => {
      return modalType === "create"
        ? Yup.string()
            .required("Contraseña es requerida")
            .min(6, "Contraseña debe tener al menos 8 caracteres")
            .test(
              "password",
              `Formato incorrecto, debe contener al menos 1 mayúscula, 1 minúscula, 1 número y 1 carácter especial`,
              (value: any) =>
                value.match(
                  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$/
                )?.length > 0
            )
        : Yup.string();
    }),
    phone: Yup.string()
      .required("Campo es requerido")
      .max(20, "Número es muy largo, máximo 20 caracteres"),
    status: Yup.string(),
    roleId: Yup.number()
      .required("El rol es requerido")
      .typeError("Campo es requerido"),
  });

  const defaultValues = {
    id: data?.id ?? 0,
    username: data?.username ?? "",
    firstName: data?.firstName ?? "",
    lastName: data?.lastName ?? "",
    password: data?.password ?? "",
    phone: data?.phone ?? "",
    roleId: data?.roleId ?? 0,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<AddOrUpdateUserTypes>({
    defaultValues,
    //@ts-ignore
    resolver: yupResolver(validation),
    mode: "onChange",
  });

      
  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {modalType === "create" ? "Agregar usuario" : "Actualizar usuario"}
        {data?.status === "DELETED" && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleActiveUser}
          >
            Activar usuario
          </Button>
        )}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
      <form>
          <Grid container spacing={4} maxWidth="lg" sx={{ pt: 4, pb: 8 }}>
          <Grid item xs={12}>
              <Controller
                name={"username"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Correo Electrónico"
                    type="email"
                    variant="standard"
                    value={value}
                    autoComplete="email"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("username")}
                    error={errors.username && Boolean(errors.username)}
                    helperText={errors.username && errors.username.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"firstName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="firstName"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("firstName")}
                    error={errors.firstName && Boolean(errors.firstName)}
                    helperText={errors.firstName && errors.firstName.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"lastName"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Apellido"
                    type="text"
                    variant="standard"
                    value={value}
                    autoComplete="lastName"
                    sx={{ "& input": { pl: "0!important" } }}
                    {...register("lastName")}
                    error={errors.lastName && Boolean(errors.lastName)}
                    helperText={errors.lastName && errors.lastName.message}
                  />
                )}
              />
            </Grid>
            {modalType === "create" && (
              <Grid item xs={12}>
                <Controller
                  name={"password"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Contraseña"
                      type={showPassword ? "text" : "password"}
                      variant="standard"
                      value={value}
                      autoComplete="off"
                      inputProps={{
                        autoComplete: "no-pass",
                      }}
                      sx={{ "& input": { pl: "0!important" } }}
                      {...register("password")}
                      error={!!errors.password}
                      helperText={errors.password && errors.password.message}
                      
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                name={"phone"}
                control={control}
                render={({ field: { onChange, name, value } }) => (
                  <FormControl sx={{ width: "100%", marginTop: "20px" }}>
                    <FormLabel
                      sx={{
                        color: theme.palette.secondary.dark,
                        position: "absolute",
                        left: "-10px",
                        top: "-26px",
                        transform: "translate(0, -1.5px) scale(0.75)",
                      }}
                    >
                      Teléfono
                    </FormLabel>
                    <PatternFormat
                      className="input-phone"
                      name={name}
                      onPaste={(e: any) => e.preventDefault()}
                      mask="_"
                      format={"####-####"}
                      value={value}
                      onChange={onChange}
                      placeholder={"####-####"}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            
            <Grid item xs={12}>
              <Controller
                control={control}
                name="roleId"
                rules={{ required: "El campo de rol es obligatorio" }}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth variant="standard" size="small" error={!!errors.roleId}>
                    <InputLabel>Perfil</InputLabel>
                    <Select
                      value={value}
                      onChange={onChange}
                      label="Perfil"
                      sx={{ "& input": { pl: "0!important" } }}
                    >
                      {roleData && roleData.content.map((role: any) => (
                        <MenuItem key={role.id} value={role.id}>{role.roleName}</MenuItem>
                      ))}
                    </Select>
                    {errors.roleId && <span>{errors.roleId.message}</span>}
                  </FormControl>
                )}
              />
            </Grid>

          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ pb: 4, px: 4 }}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Aceptar
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            mt: 2,
            mr: 2,
          }}
          onClick={cancelModal}
          autoFocus
        >
          Cancelar
        </Button>{" "}
      </DialogActions>
    </>
  );
};

export default AddOrUpdateUserModal;
