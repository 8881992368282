const FakeDataInventario = {"content": [{
        "id": 1,
        "familia": "Electrónica",
        "subFamilia": "Computadoras",
        "subSubFamilia": "Portátiles",
        "numeroEquipo": "EQ-123",
        "categoria": "Insumos",
        "codigoOficial": 4567,
        "tipo": "Laptop",
        "marca": "Dell",
        "color": "Negro",
        "codigoSerie": "DL12345",
        "fechaAdquisicion": "2023-08-10",
        "costo": 1500.99,
        "cantTotal": 10,
        "cantDisponible": 4,
        "ubicacionBodega": "Sector A3",
        "estado": true,
        "disponibilidad": "Disponible",
        "notas": "Equipo en buen estado"
      },
      {
        "id": 2,
        "familia": "Herramientas",
        "subFamilia": "Manuales",
        "subSubFamilia": "Destornilladores",
        "numeroEquipo": "EQ-456",
        "categoria": "Herramientas",
        "codigoOficial": 7890,
        "tipo": "Destornillador",
        "marca": "Bosch",
        "color": "Azul",
        "codigoSerie": "BS7890",
        "fechaAdquisicion": "2022-05-20",
        "costo": 25.50,
        "cantTotal": 50,
        "cantDisponible": 30,
        "ubicacionBodega": "Sector B1",
        "estado": false,
        "disponibilidad": "En proyecto",
        "notas": "Requiere revisión"
      }
    ]
  }

  export default FakeDataInventario