import { Outlet } from "react-router-dom";
import {
  Box,
  Stack,
  Paper,
  styled,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { useApp } from "../../hooks/useApp";
import MessageManager from "../../components/MessageManager";
import Footer from "../../components/Footer";
import Loading from "../../components/Loading";
import { CONSTANT } from "../../utils/UrlContants";
import { ReactComponent as Logo } from "../../resources/images/asap.svg";
import LogoMR from "../../resources/images/mrpintores-logo.png";

const Content = styled(Stack)(({ theme }) => ({
  minHeight: "100vh",
  padding: theme.spacing(0, 2),
}));

const LinkTo = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: "none",
}));

const Auth = () => {
  const { errorMsg, successMsg, resetErrorMsg, resetSuccessMsg, isLoading } =
    useApp();
  const theme = useTheme();

  return (
    <>
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=""
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=""
          callback={resetSuccessMsg}
        />
      )}
      {isLoading && <Loading />}
      {/* <Content 
      // justifyContent="center" 
      // alignItems="center" 
      // spacing={2}
      > */}
        {/* <Box>
          <Logo /> 
           <div>
            <img src={LogoMR} alt="Logo" style={{ width: '300px', height: 'auto' }} /> 
          </div>
        </Box> */}
        {/* <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            //width: "430px",
            maxWidth: "100%",
            position: "relative",
            mx: "auto",
            mb: `${theme.spacing(8)}!important`,
          }}
        > */}
          {/* <Paper
            sx={{
              background: "#FFFFFF",
              py: 8,
              px: 8,
              //boxShadow: "none",
            }}
          > */}
            <Outlet />
          {/* </Paper> */}
        {/* </Stack> */}
        {/* <Footer /> */}
      {/* </Content> */}
    </>
  );
};

export default Auth;
