import { createTheme } from "@mui/material/styles";

let theme = createTheme();

interface customGrey {
  greyDue: {
    main: string;
    secondary?: string;
    light?: string;
    dark?: string;
  };
}

declare module "@mui/material/styles" {
  interface Palette extends customGrey {}
  interface PaletteOptions extends customGrey {}
}

theme = createTheme({
  palette: {
    primary: {
      main: "#CC0000",
      light: "#FFFFFF",
      dark: "#D71F27",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#E65F2B",

      //main: "#CC0000",
      light: "#FFFFFF",
      dark: "#000606",
      contrastText: "#ffffff",
    },
    info: {
      main: "#CC0000",
      light: "#FFFFFF",
      dark: "#3e627c",
      contrastText: "#ffffff",
    },
    greyDue: {
      main: "#A2A2A2",
      dark: "#212529",
      light: "#ABABAB",
    },
    success: {
      main: "#CC0000",
    },
    error: {
      main: "#FF0000",
      dark: "#CC0000",
    },
    text: {
      primary: "#000606",
      secondary: "#888b8e",
    },
    background: {
      default: "#ffffff",
    },
  },
  typography: {
    fontFamily: `"Poppins", sans-serif`,
    h1: {
      fontSize: "3.5125rem",
      fontWeight: 700,
      fontFamily: `"Poppins", sans-serif`,
    },
    h2: {
      fontSize: "2.7125rem",
      fontWeight: 700,
      fontFamily: `"Poppins", sans-serif`,
    },
    h3: {
      fontSize: "1.3125rem",
      fontFamily: `"Poppins", sans-serif`,
      fontWeight: 700,
    },
    h5: {
      fontSize: "1.3125rem",
      fontWeight: 700,
      fontFamily: `"Poppins", sans-serif`,
    },
    h6: {
      fontSize: "1.05rem",
      fontWeight: 700,
      fontFamily: `"Poppins", sans-serif`,
    },
    button: {
      textTransform: "none",
      fontSize: "1rem",
    },
    subtitle1: {
      fontSize: "1rem",
    },
    body1: {
      fontSize: "1rem",
      fontFamily: `"Poppins", sans-serif`,
      fontWeight: 400,
    },
    caption: {
      fontSize: ".8125rem",
    },
  },
  spacing: 4,
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: theme.palette.primary.contrastText,
          color: "#5d5e60",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#e4e8ed",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          boxShadow: "none",
          borderRight: "1px solid #e4e8ed",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "14px",
          marginRight: "0.75rem",
          color: "#FFFFFF",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          marginTop: "0",
          marginBottom: "0",
          color: "#FFFFFF",
          "& .MuiTypography-root": {
            fontWeight: "400",
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          margin: "10px 0 0",
          "&:hover, &.expande_true": {
            backgroundColor: "#f8f9fa",
            "&.expande_true": {
              borderRadius: "22px 22px 0 0",
            },
            "& .MuiListItemText-root, & .MuiListItemIcon-root, & .MuiSvgIcon-root":
              {
                color: "#E65F2B",
              },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInput-underline:before": {
            borderBottom: "2px solid #CACACA",
          },
          "& label": {
            color: "#5d5e60",
          },
          "& .Mui-disabled": {
            paddingLeft: "0!important",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "& .MuiInput-underline:before": {
            borderBottom: "2px solid #CACACA",
          },
        },
        underline: {
          "&:before": {
            borderBottom: "2px solid #CACACA",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          //background: "#E5E5E540",
          "& .MuiInput-underline:before": {
            borderBottom: "2px solid #CACACA",
          },
        },
        input: {
          padding: "0.5rem 16px!important",
          "& .MuiInput-underline:before": {
            borderBottom: "2px solid #CACACA",
          },
          "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus":
            {
              WebkitTextFillColor: "#71959D!important",
              WebkitBoxShadow: "0 0 0px 1000px transparent inset",
              transition: "background-color 5000s ease-in-out 0s",
            },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "0.5rem",
        },
      },
    },
    /* MuiOutlinedInput: {
      styleOverrides: {
        root: {  
          '&.Mui-focused': {
            '& fieldset': {
              borderWidth: '1px!important',
              borderColor: '#f1b80e!important'
            }            
          },
          '&:hover': {
            '& fieldset': {
              borderWidth: '1px',
              borderColor: '#f1b80e!important'
            }            
          },
        }  
      }
    }, */
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            paddingTop: 0,
            paddingBottom: 0,
          },
          "& .MuiInput-input": {
            paddingLeft: "0!important",
          },
        },
        endAdornment: {
          top: "calc(50% - 10px)",
        },
        popupIndicator: {
          backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E\")",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "16px 12px",
          "& svg": {
            opacity: 0,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          color: "#5C5D60",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          //boxShadow: '0 0 .875rem 0 rgba(33,37,41,.05)',
          background: theme.palette.primary.contrastText,
          borderRadius: "10px",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          marginBottom: "1rem",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:last-child th, :last-child td": {
            borderBottom: "1px solid #dee2e6!important",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "15px",
          //textAlign: 'center',
          color: "#86939e",
          padding: theme.spacing(1.5),
          borderBottom: "1px solid #dee2e6",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#e1e8ee",
          "& .MuiTableCell-head": {
            color: "#3e627c",
            fontWeight: "700",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: "0px",
          color: "#d32f2f",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: " 4px 0 2px",
          "& .MuiInput-underline:before": {
            borderBottom: "2px solid #CACACA",
          },
          "&.Mui-disabled": {
            WebkitTextFillColor: "rgba(255, 255, 255, 0.8)",
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          borderWidth: "1px",
          borderRadius: "32px",
          "&.Mui-disabled": {
            color: "inherit!important",
            opacity: "0.5",
          },
          "& .MuiTouchRipple-root": {
            display: "none",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "0.5rem",
          fontWeight: "600",
          padding: "6px 12px",
          fontSize: "0.9rem",
        },
      },
    },
    /*  MuiButton: {
      styleOverrides: {
        root: { 
          borderRadius: '0.5rem',     
          boxShadow: 'none!important', 
          fontWeight: 400,   
          '&.MuiButton-containedPrimary': {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: '#00800A', 
            padding: '1px 16px',
            '&:hover': {            
              background: '#ffffff',
              color: '#5C5D60',
              borderColor: '#00800A'  
            }
          },  
          '&.MuiButton-containedSecondary': {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: '#BCBEC0', 
            padding: '1px 16px',
            '&:hover': {            
              //background: '#ffffff',
              //color: '#5C5D60',
              //borderColor: '#f1b80e'  
            }
          },
          
        }
      }    
    }, */
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected:hover": {
            backgroundColor: "#f1b80e",
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: "#e1e8ee",
          color: "#3E627C",
          fontWeight: 400,
          fontSize: "1.15rem",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          borderTop: "1px solid #DEE2E6",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          color: "#86939e",
        },
      },
    },
  },
});

export default theme;
